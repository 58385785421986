( function() {
    'use strict';

    function ServicesSectionController() {
        var vm = this;

        vm.$onInit = function() {
        }
    }

    angular.module( 'components' )

    .controller( 'ServicesSectionController', [ ServicesSectionController ] );
} )();