(function() {
	'use strict';

	var root = {
		templateUrl : 'root.html'
	};
	
	angular.module( 'root' )
	
	.component( 'root', root );
})();